import { Global, css } from "@emotion/react";
import useSite from "../../hooks/use-site";

const GlobalStyles = () => {
  const { themeStylesheet } = useSite();
  if (!themeStylesheet || !themeStylesheet.length) {
    return <></>;
  }
  return (
    <>
      {themeStylesheet.map(({ value }, i) => (
        <Global
          key={i}
          styles={css`
            ${value}
          `}
        />
      ))}
    </>
  );
};

export default GlobalStyles;
