// Define Gravity Form field names and unique props.
const baseFields = `
  id
  label
  description
  errorMessage
  isRequired
  placeholder
  size
  visibility
  value
  cssClass
  layoutGridColumnSpan
  labelPlacement
`;

const fieldProps = {
  TextField: `
    ${baseFields}
    inputName
    defaultValue
    maxLength
  `,
  TextAreaField: `
    ${baseFields}
    inputName
    defaultValue
  `,
  PhoneField: `
    ${baseFields}
  `,
  EmailField: `
    ${baseFields}
  `,
  SelectField: `
    ${baseFields}
    defaultValue
    choices {
      text
      value
    }
  `,
};

export default fieldProps;
