import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import AccordionTitle from "./AccordionTitle";
import BenefitTable from "./BenefitTable";
import useSite from "../../../hooks/use-site";
import { wpStyles } from "../../../theme/wp-styles";

const MonthlyRates = () => {
  const {
    planData: { monthlyRates },
  } = useSite();
  const { sectionTitle, rateTables } = monthlyRates;
  return (
    <AccordionItem>
      <AccordionButton aria-label={sectionTitle}>
        <AccordionTitle title={sectionTitle} />
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {rateTables &&
          rateTables.map(
            (
              {
                pricingTable,
                tableColorRules,
                tableEvenColumn,
                disclaimerText,
                firstColumnWidth,
                borderWidth,
                borderColor,
                textAlign,
                tableText,
              },
              key
            ) => (
              <Box key={key}>
                {pricingTable && (
                  <BenefitTable
                    table={pricingTable}
                    tableColorRules={tableColorRules}
                    tableEvenColumn={tableEvenColumn}
                    firstColumnWidth={firstColumnWidth}
                    borderWidth={borderWidth}
                    borderColor={borderColor}
                    textAlign={textAlign}
                  />
                )}
                {tableText && (
                  <Box
                    mt="4"
                    fontSize={wpStyles.fontSize.sm}
                    dangerouslySetInnerHTML={{ __html: tableText }}
                  />
                )}
                {disclaimerText && (
                  <Box
                    mt="4"
                    fontSize={wpStyles.fontSize.sm}
                    dangerouslySetInnerHTML={{ __html: disclaimerText }}
                  />
                )}
              </Box>
            )
          )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default MonthlyRates;
