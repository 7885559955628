import { Box, Button, Stack } from "@chakra-ui/react";
import Link from "next/link";
import useSite from "../../../hooks/use-site";
import { wpStyles } from "../../../theme/wp-styles";

const HeaderButtons = () => {
  const {
    themeGeneralSettings: {
      siteSettings: { headerButtons },
    },
  } = useSite();
  return (
    <Stack>
      {headerButtons.map(({ label, url, variant }, i) => (
        <Box
          key={i}
          sx={{
            a: {
              textDecor: "none",
            },
          }}
        >
          <Link href={url.uri}>
            <a>
              <Button as="span" variant={variant} fontSize="sm">
                {label}
              </Button>
            </a>
          </Link>
        </Box>
      ))}
    </Stack>
  );
};

export default HeaderButtons;
