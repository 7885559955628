import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import useSite from "../../../hooks/use-site";

import AccordionTitle from "./AccordionTitle";
import BenefitSection from "./BenefitSection";

const AssociationBenefits = () => {
  const {
    planData: { associationBenefits },
  } = useSite();
  const {
    sectionTitle,
    consumerDiscounts,
    healthCareSolutions,
    smallBusinessDiscounts,
  } = associationBenefits;
  return (
    <AccordionItem>
      <AccordionButton aria-label={sectionTitle}>
        <AccordionTitle title={sectionTitle} />
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel
        py={6}
        bg="blackAlpha.100"
        border="1px"
        borderColor="gray.300"
      >
        {consumerDiscounts.benefits.length ? (
          <BenefitSection data={consumerDiscounts} />
        ) : null}
        {healthCareSolutions.benefits.length ? (
          <BenefitSection data={healthCareSolutions} />
        ) : null}
        {smallBusinessDiscounts.benefits.length ? (
          <BenefitSection data={smallBusinessDiscounts} />
        ) : null}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AssociationBenefits;
