export const wpStyles = {
  colors: {
    primary: "var(--wp--preset--color--primary)",
    "primary-light": "var(--wp--preset--color--primary-light)",
    secondary: "var(--wp--preset--color--secondary)",
    "secondary-light": "var(--wp--preset--color--secondary-light)",
    "secondary-bright": "var(--wp--preset--color--secondary-bright)",
    "cyan-dark": "var(--wp--preset--color--cyan-dark)",
    "font-default": "var(--wp--preset--color--font-default)",
    border: "var(--wp--preset--color--border-color)",
  },
  fontSize: {
    sm: "var(--wp--preset--font-size--small)",
    md: "var(--wp--preset--font-size--medium)",
    lg: "var(--wp--preset--font-size--large)",
    xl: "var(--wp--preset--font-size--x-large)",
    gigantic: "var(--wp--custom--typography--font-size--gigantic)",
    colossal: "var(--wp--custom--typography--font-size--colossal)",
  },
};
