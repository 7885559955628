// Chakra UI Theme Customizations
// https://chakra-ui.com/docs/styled-system/theming/theme

import { extendTheme } from "@chakra-ui/react";
import { containerTheme } from "./components/Container";
import { buttonTheme } from "./components/Button";
import { tabsTheme } from "./components/Tabs";
import { headingTheme } from "./components/Heading";
import { css } from "@emotion/react";
import { wpStyles } from "./wp-styles";

const colors = {
  theme: {
    100: "#eaedf8",
    200: "#d0d8f0",
    500: "#23346b",
    600: "#1f2e5e",
    700: "#1b2851",
    800: "#0e152b",
    900: "#060811",
  },
  pink: {
    100: "#fdf8f9",
  },
  blue: {
    200: "#e9f2f8",
    500: "#014c63",
  },
  green: {
    200: "#ebf8f1",
    500: "#27a975",
  },
};

const fonts = {
  heading: "'Titillium Web', sans-serif",
  body: "'Titillium Web', sans-serif",
};

const fontSizes = {
  md: "var(--wp--preset--font-size--medium)",
  lg: "var(--wp--preset--font-size--large)",
  xl: `var(--wp--preset--font-size--x-large)`,
};

const Heading = {
  baseStyle: {
    color: "gray.700",
  },
  sizes: {
    md: {
      fontSize: "var(--wp--preset--font-size--medium)",
    },
    lg: {
      fontSize: "var(--wp--preset--font-size--large)",
    },
  },
};

const global = {
  ul: {
    pl: 6,
    mt: 4,
  },
  a: {
    _hover: {
      color: wpStyles.colors.primary,
    },
  },
};

const chakraTheme = extendTheme({
  fonts: fonts,
  colors: colors,
  fontSizes: {
    ...wpStyles.fontSize,
  },
  styles: {
    global: global,
  },
  components: {
    Container: containerTheme,
    Button: buttonTheme,
    Tabs: tabsTheme,
    Heading: headingTheme,
  },
});

export default chakraTheme;
