import React, { useEffect } from "react";
import {
  Flex,
  Text,
  Stack,
  Collapse,
  Icon,
  Link,
  useColorModeValue,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { wpStyles } from "../../../theme/wp-styles";

const MobileNavItem = ({ label, children, url }) => {
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      document.body.styles = {
        overflow: "hidden",
        position: "fixed",
      };
    }
  }, [isOpen]);

  function handleItemClick(e) {
    e.preventDefault();
    if (children && children.length) {
      onToggle();
    } else {
      window.location = url;
    }
  }

  return (
    <Stack spacing={4} px={2} fontSize={wpStyles.fontSize.sm}>
      <Flex
        py={2}
        as={Link}
        href={url ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
        onClick={handleItemClick}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
          as="span"
        >
          {label}
        </Text>
        {children.length ? (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        ) : (
          <></>
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity mt="0 !important" p={0}>
        <Stack
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children.length ? (
            children.map(({ url, label }, i) => (
              <Link
                key={i}
                href={url}
                role={"group"}
                display={"block"}
                p={2}
                rounded={"md"}
                w="100%"
                _hover={{ bg: "theme.100" }}
              >
                <Stack direction={"row"} align={"center"}>
                  <Box>
                    <Text
                      as="span"
                      transition={"all .3s ease"}
                      _groupHover={{ color: "theme.500" }}
                      fontWeight={500}
                    >
                      {label}
                    </Text>
                  </Box>
                </Stack>
              </Link>
            ))
          ) : (
            <></>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default MobileNavItem;
