import { gql } from "@apollo/client";
import fieldProps from "./gravity-forms-field-props";
/**
 * Partial: retrieve basic data on all form fields.
 *
 * @author WebDevStudios
 * @return {string} Form fields query partial.
 */
function getFormFieldsPartial() {
  return (
    Object.keys(fieldProps)
      // Build individual query partials by field type.
      .map(
        (field) => `
          ... on ${field} {
            ${fieldProps[field]}
          }
        `
      )
      // Connect query partial pieces.
      .join("")
  );
}

// Fragment: retrieve single form fields.
export const SingleFormFragment = gql`
  fragment SingleFormFields on GfForm {
    formId
    title
    description
    cssClass
    submitButton {
      text
      layoutGridColumnSpan
      location
      type
      width
    }
    formFields {
      edges {
        node {
          id
          type
          ${getFormFieldsPartial()}
        }
      }
    }
  }
`;

// export const QUERY_FORM_BY_ID = gql`
//   query GetGravityForm($id: ID = "1") {
//     gfForm(id: $id, idType: DATABASE_ID) {
//       ...SingleFormFields
//     }
//   }
//   ${singleFormFragment}
// `;
