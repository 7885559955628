import { gql } from "@apollo/client";

export const BlogInfoFragment = gql`
  fragment BlogInfoFragment on GeneralSettings {
    title
    description
  }
`;

export const NavigationMenuFragment = gql`
  fragment NavigationMenuItemFragment on MenuItem {
    id
    path
    label
    parentId
    cssClasses
    menu {
      node {
        name
        menuFooterTitles {
          title
        }
      }
    }
  }
`;

export const FeatureImageFragment = gql`
  fragment FeaturedImageFragment on NodeWithFeaturedImage {
    featuredImage {
      node {
        id
        sourceUrl
        altText
        mediaDetails {
          width
          height
        }
        sizes(size: MEDIUM)
      }
    }
  }
`;

export const SiteSettingsFragment = gql`
  fragment SiteSettingsFragment on ThemeGeneralSettings {
    siteSettings {
      gtmContainerId
      footerFormId
      footerFormDescription
      footerFormTitle
      headerButtons {
        variant
        url {
          ... on Page {
            uri
          }
        }
        label
      }
    }
  }
`;
