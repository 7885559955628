import { useState } from "react";
import {
  Box,
  Heading,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Button,
  Collapse,
} from "@chakra-ui/react";
import AccordionTitle from "./AccordionTitle";
import BenefitTable from "./BenefitTable";
import parse from "html-react-parser";
import options from "../../../support/parse-settings";
import useSite from "../../../hooks/use-site";
import { wpStyles } from "../../../theme/wp-styles";

const InsuredBenefits = () => {
  const {
    planData: { insuredBenefits },
  } = useSite();
  const { sectionTitle, benefits } = insuredBenefits;
  return (
    <AccordionItem>
      <AccordionButton aria-label={sectionTitle}>
        <AccordionTitle title={sectionTitle} />
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4} position="relative">
        {benefits &&
          benefits.map((benefit, key) => (
            <Box
              mb={8}
              pb={8}
              borderBottom="1px"
              borderBottomColor="gray.300"
              _last={{
                mb: 0,
                pb: 0,
                borderBottom: "none",
              }}
              key={key}
            >
              {benefit.name && (
                <Heading
                  as="h4"
                  mb="0"
                  size={["sm", "sm", "md"]}
                  fontWeight="400"
                  fontFamily={"body"}
                >
                  {benefit.name}
                </Heading>
              )}
              {benefit.underwriter && (
                <Heading as="h4" size={["sm", "sm", "md"]}>
                  {benefit.underwriter}
                </Heading>
              )}
              <BenefitTableDisplay {...benefit} />
            </Box>
          ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

const BenefitTableDisplay = ({
  benefitTable,
  tableColorRules,
  tableEvenColumn,
  disclaimers,
  disclaimerText,
  firstColumnWidth,
  borderWidth,
  borderColor,
  textAlign,
}) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  const {
    planData: { productColor },
  } = useSite();
  return (
    <>
      <Button
        size="md"
        bg={productColor}
        fontSize={wpStyles.fontSize.sm}
        color="white"
        _hover={{ bg: "black" }}
        onClick={handleToggle}
        mb={show ? 4 : 0}
        mt={4}
        variant="solid"
      >
        {show ? "Hide" : "Show"} Benefit Table
      </Button>
      <Collapse in={show}>
        {benefitTable && (
          <BenefitTable
            table={benefitTable}
            tableColorRules={tableColorRules}
            tableEvenColumn={tableEvenColumn}
            firstColumnWidth={firstColumnWidth}
            borderWidth={borderWidth}
            borderColor={borderColor}
            textAlign={textAlign}
          />
        )}
        {disclaimers && (
          <>
            <Box as="strong" display="block" my="4">
              Disclaimers
            </Box>
            <Flex gap="4">
              {disclaimers.map(({ file_label, file_upload }, key) => (
                <Button
                  as="a"
                  href={file_upload.url}
                  bg={productColor ? productColor : `primary.500`}
                  _hover={{
                    bg: "black",
                    color: "white",
                  }}
                  color="white"
                  target="_blank"
                  key={key}
                  border="none"
                  fontSize={wpStyles.fontSize.sm}
                >
                  {file_label}
                </Button>
              ))}
            </Flex>
          </>
        )}
        {disclaimerText && (
          <Box
            fontSize={wpStyles.fontSize.sm}
            bg="blackAlpha.200"
            px={8}
            py={4}
            mt="4"
            sx={{
              p: {
                position: "relative",
                mt: 0,
                mb: 1,
                _last: {
                  mb: 0,
                },
              },
              sup: {
                fontWeight: "bold",
                position: "absolute",
                top: "13px",
                left: "-13px",
              },
            }}
          >
            {parse(disclaimerText, options)}
          </Box>
        )}
      </Collapse>
    </>
  );
};

export default InsuredBenefits;
