import { Box, Accordion } from "@chakra-ui/react";
import AssociationBenefits from "./AssociationBenefits";
import Faq from "./Faq";
import HealthCarePrograms from "./HealthCarePrograms";
import InsuredBenefits from "./InsuredBenefits";
import MonthlyRates from "./MonthlyRates";
import Eligibility from "./Eligibility";
import useSite from "../../../hooks/use-site";
import { useState } from "react";

const BenefitAccordions = () => {
  const {
    planData: {
      associationBenefits,
      healthCarePrograms,
      insuredBenefits,
      monthlyRates,
      faq,
      eligibility,
      title,
    },
  } = useSite();

  // const [openTabs, setOpenTabs] = useState([]);

  let defaultIndex = [2];

  if (!healthCarePrograms.benefits.length) {
    defaultIndex[0] = 1;
  }

  return (
    <Box fontSize={"md"} my={8}>
      <Box as="h4" mb={4}>
        Click Each Tab Below to Learn More About {title}
      </Box>
      <Accordion allowMultiple defaultIndex={defaultIndex}>
        {associationBenefits && <AssociationBenefits />}
        {healthCarePrograms && <HealthCarePrograms />}
        {insuredBenefits && insuredBenefits.benefits.length ? (
          <InsuredBenefits />
        ) : (
          <></>
        )}
        {monthlyRates && <MonthlyRates />}
        {faq && <Faq />}
        {eligibility && <Eligibility />}
      </Accordion>
    </Box>
  );
};
export default BenefitAccordions;
