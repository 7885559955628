import { Box, Container, Flex, Image, Spacer, Stack } from "@chakra-ui/react";
import { useScroll } from "framer-motion";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import logo from "../../assets/awa-logo.svg";
import DesktopNav from "../MainNav/components/DesktopNav";
import MobileNav from "../MainNav/components/MobileNav";
import HeaderButtons from "./components/HeaderButtons";

const Header = () => {
  const ref = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [pastFold, setPastFold] = useState(false);

  const { scrollY } = useScroll();

  useEffect(() => {
    return scrollY.onChange((latest) => {
      if (latest > 200) {
        setPastFold(true);
      } else {
        setPastFold(false);
      }
    });
  }, [scrollY]);

  useEffect(() => {
    if (ref?.current) {
      setHeaderHeight(`${ref.current.clientHeight}px`);
    }
  }, [ref]);
  return (
    <Box as="header" h={headerHeight}>
      <Box
        ref={ref}
        py={4}
        position="fixed"
        left="0"
        top="0"
        right="0"
        bg="white"
        zIndex="sticky"
        opacity={pastFold ? 0.8 : 1.0}
        _hover={{
          opacity: 1.0,
        }}
      >
        <Container>
          <Stack direction="row" align="center" gap={4}>
            <Box
              sx={{
                a: {
                  display: "block",
                },
                img: {
                  transition: "all 0.3s ease",
                },
              }}
            >
              <Link href="/">
                <a>
                  <Image
                    src={logo.src}
                    htmlWidth={pastFold ? "200px" : "248px"}
                    htmlHeight="51px"
                    alt="AWA Logo"
                  />
                </a>
              </Link>
            </Box>
            <Spacer />
            <Flex display={{ base: "none", lg: "flex" }} align="center" gap={8}>
              <DesktopNav />
              <HeaderButtons />
            </Flex>
            <Flex display={{ base: "flex", lg: "none" }} justify="flex-end">
              <MobileNav />
            </Flex>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default Header;
