import { Container, SimpleGrid } from "@chakra-ui/react";
import useSite from "../../hooks/use-site";
import ArchiveCard from "./components/ArchiveCard";

const Archive = () => {
  const {
    posts: { nodes },
  } = useSite();
  return (
    <Container my={12}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={4}>
        {nodes.map((post) => (
          <ArchiveCard {...post} key={post.id} />
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default Archive;
