import { HStack, Icon, Link } from "@chakra-ui/react";
import React from "react";
import { AiFillFacebook, AiFillTwitterSquare } from "react-icons/ai";
import { FaPinterestSquare } from "react-icons/fa";
import useSite from "../../../hooks/use-site";

const icons = {
  facebook: AiFillFacebook,
  twitter: AiFillTwitterSquare,
  pinterest: FaPinterestSquare,
};

const ShareLinks = () => {
  const {
    post: { shareLinks },
  } = useSite();

  return (
    <HStack justifyContent={"center"}>
      {Object.keys(icons).map((key) => (
        <Link href={shareLinks[key]} key={key} target="_blank" maxH="25px">
          <Icon as={icons[key]} w="25px" h="25px" />
        </Link>
      ))}
    </HStack>
  );
};

ShareLinks.defaultProps = {
  align: "left",
};

export default ShareLinks;
