import dynamic from "next/dynamic";
import { SiteContext, useSiteContext } from "../../hooks/use-site";
import GlobalStyles from "../GlobalStyles";
import Header from "../Header";
import SEO from "../SEO";
import Layout from "../Layout";

// Need to dynamically load GTM module to detect document
const DynamicFooter = dynamic(() => import("../Footer"), { ssr: false });

const SiteWrapper = ({ children, siteData }) => {
  return (
    <SiteContext.Provider value={useSiteContext(siteData)}>
      <SEO />
      <GlobalStyles />
      <Header />
      <Layout>{children}</Layout>
      <DynamicFooter />
    </SiteContext.Provider>
  );
};

SiteWrapper.defaultProps = {
  children: {},
  siteData: {},
};

export default SiteWrapper;
