import { Box } from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { formatDate, urlUpdate } from "../../../support/functions";
import { wpStyles } from "../../../theme/wp-styles";

const ArchiveCard = ({ title, date, categories, featuredImage, link }) => {
  const postDate = new Date(date);

  const category = categories ? categories.nodes[0].name : false;

  return (
    <Link href={urlUpdate(link)} passHref>
      <Box
        as="a"
        display="block"
        textDecor="none"
        textAlign="center"
        role="group"
        transition="color 0.3s ease"
      >
        {featuredImage && (
          <Box position="relative" maxH="205px" overflow="hidden">
            <Image
              src={featuredImage.node.sourceUrl}
              alt={featuredImage.node.altText}
              fill="true"
              width={`${featuredImage.node.mediaDetails.width}px`}
              height={`${featuredImage.node.mediaDetails.height}px`}
              style={{ objectFit: "cover" }}
            />
            {category && (
              <Box
                position="absolute"
                bottom="0"
                left="0"
                right="0"
                fontSize={wpStyles.fontSize.sm}
                textTransform="uppercase"
                mx="auto"
                textAlign="center"
              >
                <Box
                  as="span"
                  bg="white"
                  display="inline-block"
                  px={4}
                  color={wpStyles.colors.primary}
                >
                  {category}
                </Box>
              </Box>
            )}
          </Box>
        )}
        <Box
          fontSize={wpStyles.fontSize.md}
          fontWeight="bold"
          lineHeight="1.0"
          py={4}
          px={8}
          mb={4}
          position="relative"
          _after={{
            content: '""',
            w: "75px",
            h: "1px",
            bg: wpStyles.colors.primary,
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            mx: "auto",
            transition: "width 0.3s ease",
          }}
          _groupHover={{
            _after: {
              w: "150px",
            },
          }}
        >
          {title}
        </Box>
        <Box fontSize={wpStyles.fontSize.sm} mb={2}>
          {formatDate(postDate)}
        </Box>
      </Box>
    </Link>
  );
};

export default ArchiveCard;
