import Image from "next/image";
import useSite from "../../../hooks/use-site";
import { Box } from "@chakra-ui/react";

const NewsletterBanner = () => {
  const {
    newsletter: { newsletter },
  } = useSite();

  const {
    bannerImage: { sourceUrl, mediaDetails },
  } = newsletter;

  if (!sourceUrl) {
    return <></>;
  }

  return (
    <Box mb={8}>
      <Image
        src={sourceUrl}
        width={`${mediaDetails.width}px`}
        height={`${mediaDetails.height}px`}
        maxW="100%"
      />
    </Box>
  );
};

export default NewsletterBanner;
