import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools"; // import utility for setting light and dark mode props

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({});

// define custom sizes
const sizes = {
  //   xl: definePartsStyle({
  //     // define the parts that will change for each size
  //     tab: {
  //       fontSize: "xl",
  //       py: "4",
  //       px: "6",
  //     },
  //     tabpanel: {
  //       py: "4",
  //       px: "6",
  //     },
  //   }),
};

// define custom variants
const awaVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props; // add colorScheme as a prop

  return {
    tab: {
      borderRight: "2px",
      borderColor: "blue.500",
      p: 0,
      mb: 8,
      color: "blue.500",
      textTransform: "uppercase",
      _selected: {
        color: "green.500",
        fontWeight: "bold",
      },
      _last: {
        borderRight: "0px",
      },
    },
    tablist: {
      borderBottom: "2x solid",
      borderColor: "inherit",
    },
    tabpanel: {
      bg: "blue.200",
      p: 0,
    },
  };
});

const variants = {
  awa: awaVariant,
};

// define which sizes, variants, and color schemes are applied by default
const defaultProps = {
  variant: "awa",
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps,
});
