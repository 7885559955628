import React from "react";
import { Box, Flex, Text, Stack, Icon } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import Link from "next/link";
import { wpStyles } from "../../../theme/wp-styles";

const DesktopSubNav = ({ label, url }) => {
  return (
    <Link href={url} passHref>
      <a>
        <Box
          role={"group"}
          display={"block"}
          p={2}
          rounded={"md"}
          color={wpStyles.colors["font-default"]}
          _hover={{ bg: "green.200", color: "green.500", cursor: "pointer" }}
          fontSize={wpStyles.fontSize.sm}
        >
          <Stack direction={"row"} align={"center"}>
            <Box>
              <Text
                transition={"all .3s ease"}
                _groupHover={{ color: "green.500" }}
                fontWeight={500}
                as="span"
              >
                {label}
              </Text>
            </Box>
            <Flex
              transition={"all .3s ease"}
              transform={"translateX(-10px)"}
              opacity={0}
              _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
              justify={"flex-end"}
              align={"center"}
              flex={1}
            >
              <Icon color={"green.500"} w={5} h={5} as={ChevronRightIcon} />
            </Flex>
          </Stack>
        </Box>
      </a>
    </Link>
  );
};

export default DesktopSubNav;
