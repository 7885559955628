import page from "./page";
import singleMembershipPlans from "./single-membership-plans";
import archiveMembershipPlans from "./archive-membership-plans";
import archive from "./archive";
import singlePost from "./single-post";
import category from "./category";
import singleNewsletter from "./single-newsletters";
import archiveNewsletters from "./archive-newsletters";

export default {
  page,
  "single-membership-plans": singleMembershipPlans,
  "archive-membership-plans": archiveMembershipPlans,
  archive: archive,
  home: archive,
  "single-post": singlePost,
  category: category,
  "single-newsletters": singleNewsletter,
  "archive-newsletters": archiveNewsletters,
};
