import { Box } from '@chakra-ui/react';

const AccordionTitle = ({ title }) => {
  return (
    <Box flex="1" textAlign="left" fontWeight={'bold'} color="content.500" fontSize="lg">
      {title}
    </Box>
  );
};
export default AccordionTitle;
