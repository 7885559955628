import { useContext, createContext, useState } from "react";

export const SiteContext = createContext();

/**
 * useSiteState
 */

export function useSiteState() {
  const [headerRef, setHeaderRef] = useState(null);
  return { headerRef, setHeaderRef };
}

/**
 * useSiteContext
 */

export function useSiteContext(data) {
  const site = useSiteState();
  return {
    ...data,
    ...site,
  };
}

/**
 * useSite
 */

export default function useSite() {
  const site = useContext(SiteContext);

  const {
    headerMenuItems: { nodes: headerMenu },
  } = site;

  function getHeaderMenu() {
    return formatNodeToMenu(headerMenu);
  }

  function getMenuByName(name) {
    if (name in site) {
      const nodes = site[name].nodes;
      return {
        title: getMenuTitle(nodes),
        items: formatNodeToMenu(nodes),
      };
    } else {
      return null;
    }
  }

  function getMenuTitle(nodes) {
    return nodes[0].menu.node.menuFooterTitles.title;
  }

  function formatNodeToMenu(menu) {
    const menuItems = [];

    menu.map(({ id, parentId, label, path }) => {
      if (!parentId) {
        // Collect Children
        const childrenNodes = headerMenu.filter((item) => item.parentId === id);
        // Then organize into similar format as parent
        const children = childrenNodes.map(({ label, path, id }) => {
          return { label: label, url: path, id: id };
        });
        menuItems.push({
          id: id,
          label: label,
          url: path,
          children: children,
        });
      }
    });
    return menuItems;
  }

  return {
    ...site,
    getHeaderMenu,
    getMenuByName,
    formatMenu: formatNodeToMenu,
  };
}
