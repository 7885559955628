import ProductTitle from "./components/ProductTitle";
import BenefitAccordions from "./components/BenefitAccordions";
import {
  Container,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import useSite from "../../hooks/use-site";
import { wpStyles } from "../../theme/wp-styles";

const MembershipPlansSingle = () => {
  const { planData } = useSite();

  return (
    <>
      <ProductTitle />
      <Container>
        {planData.status === "Active" ? (
          <>
            <BenefitAccordions />
            {planData.pageDisclaimer && (
              <Box
                bg="blackAlpha.200"
                p="6"
                fontSize={wpStyles.fontSize.sm}
                dangerouslySetInnerHTML={{ __html: planData.pageDisclaimer }}
              />
            )}
          </>
        ) : (
          <Alert mb={8} status="warning">
            <AlertIcon />
            <AlertDescription>This product is not active</AlertDescription>
          </Alert>
        )}
      </Container>
    </>
  );
};

export default MembershipPlansSingle;
