import {
  Flex,
  Button,
  Heading,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
  Box,
} from "@chakra-ui/react";
import useSite from "../../../hooks/use-site";
import { wpStyles } from "../../../theme/wp-styles";
import AccordionTitle from "./AccordionTitle";

const Faq = () => {
  const {
    planData: { faq, productColor },
  } = useSite();
  const { sectionTitle, faqAttachments, carriers, additional } = faq;
  return (
    <AccordionItem>
      <AccordionButton aria-label={sectionTitle}>
        <AccordionTitle title={sectionTitle} />
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {faqAttachments && (
          <Flex gap="4">
            {faqAttachments.map(({ document_label, document }, key) => (
              <Button
                as="a"
                href={document.url}
                bg={productColor ? productColor : `primary.500`}
                _hover={{
                  bg: "black",
                  color: "white",
                }}
                color="white"
                target="_blank"
                key={key}
                variant="solid"
                size="sm"
              >
                {document_label}
              </Button>
            ))}
          </Flex>
        )}
        {carriers && (
          <Box mt="8">
            {carriers.map(({ title, logo, description }, key) => (
              <Flex
                gap="4"
                mb="8"
                pb="8"
                borderBottom="1px"
                borderBottomColor="gray.200"
                wrap={["wrap", "wrap", "nowrap"]}
                _last={{ borderBottom: "none", pb: "0", mb: "0" }}
                fontSize={wpStyles.fontSize.sm}
                key={key}
              >
                {logo && (
                  <Box w={["100%", "100%", "20%"]}>
                    <Image
                      loading="lazy"
                      maxW={["200px", "200px", "100%"]}
                      src={logo.url}
                      alt={`${title} logo`}
                    />
                  </Box>
                )}
                <Box w={["100%", "100%", "80%"]}>
                  <Box
                    dangerouslySetInnerHTML={{ __html: title }}
                    sx={{
                      h4: {
                        fontSize: wpStyles.fontSize.md,
                      },
                    }}
                  />
                  <Box dangerouslySetInnerHTML={{ __html: description }} />
                </Box>
              </Flex>
            ))}
          </Box>
        )}
        {additional && additional.length ? (
          <Box mt="8">
            {additional.map(({ title, logo, description }, key) => (
              <Flex
                gap="4"
                mb="8"
                pb="8"
                borderBottom="1px"
                borderBottomColor="gray.200"
                wrap={["wrap", "wrap", "nowrap"]}
                _last={{ borderBottom: "none", pb: "0", mb: "0" }}
                fontSize={wpStyles.fontSize.sm}
                key={key}
              >
                {logo && (
                  <Box w={["100%", "100%", "20%"]}>
                    <Image
                      loading="lazy"
                      maxW={["200px", "200px", "100%"]}
                      src={logo}
                      alt={`${title} logo`}
                    />
                  </Box>
                )}
                <Box w={["100%", "100%", "80%"]}>
                  <Heading
                    dangerouslySetInnerHTML={{ __html: title }}
                    as="h4"
                    fontSize={wpStyles.fontSize.md}
                  />
                  <Box dangerouslySetInnerHTML={{ __html: description }} />
                </Box>
              </Flex>
            ))}
          </Box>
        ) : (
          <></>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Faq;
