import { Stack, Box } from "@chakra-ui/react";
import Link from "next/link";
import useSite from "../../../hooks/use-site";
import { urlUpdate } from "../../../support/functions";
import { wpStyles } from "../../../theme/wp-styles";

const Categories = () => {
  const {
    post: { categories },
  } = useSite();

  return (
    <Stack direction="row" fontSize={wpStyles.fontSize.sm}>
      <Box as="strong">Posted In:</Box>
      {categories.nodes.map(({ link, name, categoryId }) => (
        <Link href={urlUpdate(link)} passHref key={categoryId}>
          <Box as="a">{name}</Box>
        </Link>
      ))}
    </Stack>
  );
};

export default Categories;
