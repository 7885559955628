import { Box, Button, Heading, VStack } from "@chakra-ui/react";
import useSite from "../../../hooks/use-site";

import parse from "html-react-parser";

const NewsletterSidebar = () => {
  const {
    newsletter: { newsletter, content },
  } = useSite();

  const { h2Colors } = newsletter;

  let titles = [];

  parse(content, {
    replace: ({ attribs, children, name }) => {
      if (name === "h2") {
        console.log("H2 Attribs", attribs);
        titles.push({
          label: children[0].data,
          id: attribs.id,
        });
      }
    },
  });

  function handleScrollToId(id) {
    const el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Box
      bg={h2Colors}
      sx={{
        position: "sticky",
        top: "15px",
      }}
    >
      <Heading
        as="h3"
        fontSize="xl"
        color="white"
        py={3}
        px={4}
        fontWeight="bold"
      >
        Inside the AWA Insights
      </Heading>
      <VStack align="stretch">
        {titles.map((title, index) => (
          <Button
            key={index}
            variant="ghost"
            textAlign="left"
            display="block"
            size="md"
            color="white"
            _hover={{
              bg: "transparent",
            }}
            onClick={() => handleScrollToId(title.id)}
          >
            {title.label}
          </Button>
        ))}
      </VStack>
    </Box>
  );
};

export default NewsletterSidebar;
