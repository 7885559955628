import { Box, Button, Heading } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { domToReact } from "html-react-parser";
import Link from "next/link";
import { urlUpdate } from "./functions";
import dynamic from "next/dynamic";
import { wordpressUrl, frontendUrl } from "../utilities/variables";

const GravityFormLookUp = dynamic(() =>
  import("../components/GravityForms/GravityFormLookUp")
);

const AcfBlockLookup = dynamic(() => import("../components/AcfBlockLookup"));
const MaskedImage = dynamic(() => import("../components/MaskedImage"));
const GutenbergColumns = dynamic(() =>
  import("../components/GutenbergColumns")
);
const GutenbergGroup = dynamic(() => import("../components/GutenbergGroup"));
const ListStyleCheckmark = dynamic(() =>
  import("../components/ListStyleCheckmark")
);
const ColorBoxes = dynamic(() => import("../components/ColorBoxes"));
const ButtonGroupWrapper = dynamic(() =>
  import("../components/ButtonGroupWrapper")
);

const headers = ["h1", "h2", "h3", "h4"];

const options = {
  replace: ({ attribs, children, name }) => {
    const classNames = attribs?.class ? attribs.class : "";
    const styles = attribs?.style;

    // Remove ANY inline scripting
    if (name === "script") return <></>;

    // Replace Gravity Forms w/ Custom Component
    if (classNames && classNames.includes("gform_wrapper")) {
      const formId = attribs.id.split("_").pop();
      return <GravityFormLookUp formId={formId} />;
    }

    // Render ACF Blocks
    if (classNames && classNames.includes("acf-block"))
      return <AcfBlockLookup blockId={attribs["data-id"]} />;

    if (headers.includes(name))
      return (
        <Heading
          as={name}
          className={classNames}
          css={css`
            ${styles}
          `}
          id={attribs?.id}
        >
          {domToReact(children, options)}
        </Heading>
      );

    if (classNames && classNames.includes("wp-block-columns"))
      return (
        <GutenbergColumns attribs={attribs} styles={styles}>
          {children}
        </GutenbergColumns>
      );

    if (classNames && classNames.includes("wp-block-group"))
      return (
        <GutenbergGroup attribs={attribs} styles={styles}>
          {children}
        </GutenbergGroup>
      );

    if (
      name === "figure" &&
      children &&
      children.length &&
      classNames.includes("masked")
    ) {
      const img = children[0].attribs;
      // console.log("Masked", attribs, children);
      return <MaskedImage img={img} />;
    }

    if (classNames && classNames.includes("wp-block-buttons"))
      return (
        <ButtonGroupWrapper className={classNames}>
          {children}
        </ButtonGroupWrapper>
      );

    if (classNames && classNames.includes("wp-block-button")) {
      return (
        <Button href={urlUpdate(children[0].attribs.href)} as="a">
          {domToReact(children[0].children)}
        </Button>
      );
    }

    if (classNames && classNames.includes("color-boxes"))
      return (
        <ColorBoxes
          name={name}
          classNames={classNames}
          styles={styles}
          children={children}
        />
      );

    if (name === "ul" && classNames && classNames.includes("checkmark"))
      return <ListStyleCheckmark>{children}</ListStyleCheckmark>;

    if (
      name === "a" &&
      attribs?.href &&
      attribs?.href.includes(frontendUrl) &&
      attribs?.href.includes("pdf")
    ) {
      return (
        <Box
          as="a"
          css={css`
            ${styles}
          `}
          className={classNames}
          cursor="pointer"
          href={attribs.href.replace(frontendUrl, wordpressUrl)}
          target="_blank"
        >
          {domToReact(children, options)}
        </Box>
      );
    }
    if (
      name === "a" &&
      attribs?.href &&
      attribs?.href.includes(wordpressUrl) &&
      !attribs?.href.includes("pdf")
    ) {
      return (
        <Link href={urlUpdate(attribs.href)} passHref>
          <Box
            as="a"
            css={css`
              ${styles}
            `}
            className={classNames}
            cursor="pointer"
          >
            {domToReact(children, options)}
          </Box>
        </Link>
      );
    }
  },
};

export default options;
