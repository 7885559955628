import { TableContainer, Table, Tr, Td, Tbody } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { wpStyles } from "../../../theme/wp-styles";

const BenefitTable = ({
  table,
  tableColorRules,
  tableEvenColumn,
  firstColumnWidth,
  borderColor,
  borderWidth,
}) => {
  if (!table) {
    return <></>;
  }

  return (
    <TableContainer whiteSpace={"normal"} fontSize={wpStyles.fontSize.sm}>
      <Table
        variant={"simple"}
        width="100%"
        border="1px"
        borderColor={"gray.200"}
        sx={{
          td: {
            border: borderWidth ? `${borderWidth}px` : "1px",
            borderStyle: "solid",
            borderColor: borderColor ? borderColor : "gray.200",
            p: "8px",
          },
        }}
      >
        <Tbody>
          {table.map((cells, key) => (
            <Tr key={key}>
              <BenefitTableRow
                cells={cells}
                position={key}
                table={table}
                tableColorRules={tableColorRules}
                tableEvenColumn={tableEvenColumn}
                firstColumnWidth={firstColumnWidth}
              />
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const BenefitTableRow = ({
  cells,
  position,
  table,
  tableColorRules,
  tableEvenColumn,
  firstColumnWidth,
}) => {
  const [isRowSpan, setIsRowSpan] = useState(false);

  // Used to look up next row array to determine if it has #rowspan#
  const checkRowForRowSpan = useCallback(
    (index) => {
      if (index in table) {
        // Slice starting from 2nd Cell to end of row
        // First cell will always be benefit label
        const nextCells = table[index].slice(1, cells.length);
        // Filters for all texts matching 2nd cell
        const nextCellsContent = nextCells.filter((e, i, a) => e === a[0]);
        return (
          nextCellsContent.length === nextCells.length &&
          nextCellsContent[0] === "#rowspan#"
        );
      } else {
        return false;
      }
    },
    [table, cells]
  );

  // Used to see if any cells
  function checkRowForColSpan(cellNumber) {
    const nextCells = cells.slice(cellNumber, cells.length);
    const nextCellsContent = nextCells.filter((e, i, a) => e === a[0]);
    return (
      nextCellsContent.length === nextCells.length &&
      nextCellsContent[0] === "#colspan#"
    );
  }

  useEffect(() => {
    let count = position + 1;
    const hasRowSpan = table[position][1] != "#rowspan#";
    let rowSpanAmount = 1;
    if (count in table && hasRowSpan) {
      while (checkRowForRowSpan(count++)) {
        rowSpanAmount++;
      }
      setIsRowSpan(rowSpanAmount);
    }
  }, [checkRowForRowSpan, position, table]);

  return (
    <>
      {cells.map((cell, i) => {
        const isColspan = checkRowForColSpan(i + 1);

        // Do not render anything #colspan# or #rowspan#
        if (cell == "#colspan#" || cell == "#rowspan#") {
          return null;
        }
        return (
          <Td
            colSpan={isColspan ? cells.length : undefined}
            rowSpan={isRowSpan && i !== 0 ? isRowSpan : undefined}
            key={i}
            valign={"center"}
            textAlign={i !== 0 ? "center" : "left"}
            {...setCellProps(
              tableColorRules,
              tableEvenColumn,
              firstColumnWidth,
              position + 1,
              i + 1
            )}
          >
            <span dangerouslySetInnerHTML={{ __html: cell }} />
          </Td>
        );
      })}
    </>
  );
};

function setCellProps(
  tableColorRules,
  tableEvenColumn,
  firstColumnWidth,
  rowNumber,
  cellNumber
) {
  // console.log(firstColumnWidth);
  // Assign each prop as variable
  let bgColor = cellNumber % 2 === 0 ? tableEvenColumn : `white`;
  let fontColor = "content.100";
  let fontWeight = "normal";
  let width =
    cellNumber === 1 && firstColumnWidth ? `${firstColumnWidth}%` : "auto";

  if (tableColorRules) {
    tableColorRules.map(
      ({ rows, font_color, cell_selection, background_color, bold_font }) => {
        const ruleRow = rows.map(Number);

        if (ruleRow.includes(rowNumber)) {
          if (cell_selection === "all") {
            if (background_color) {
              bgColor = background_color;
            }
            if (font_color) {
              fontColor = font_color;
            }
            if (bold_font) {
              fontWeight = "bold";
            }
          }

          if (cell_selection === "even" && cellNumber % 2 === 0) {
            if (background_color) {
              bgColor = background_color;
            }
            if (font_color) {
              fontColor = font_color;
            }
            if (bold_font) {
              fontWeight = "bold";
            }
          }

          if (cell_selection === "odd" && cellNumber % 2 !== 0) {
            if (background_color) {
              bgColor = background_color;
            }
            if (font_color) {
              fontColor = font_color;
            }
            if (bold_font) {
              fontWeight = "bold";
            }
          }

          if (cell_selection === "first" && cellNumber === 1) {
            if (background_color) {
              bgColor = background_color;
            }
            if (font_color) {
              fontColor = font_color;
            }
            if (bold_font) {
              fontWeight = "bold";
            }
          }
        }
      }
    );
  }

  return {
    bgColor: bgColor,
    color: fontColor,
    fontWeight: fontWeight,
    width: width,
  };
}

export default BenefitTable;
