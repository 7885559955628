import "../faust.config";
import React, { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { FaustProvider } from "@faustwp/core";
import { ChakraProvider } from "@chakra-ui/react";
import chakraTheme from "../theme";
import "../styles/global.scss";
import { AnimatePresence } from "framer-motion";
import PageLoader from "../components/PageLoader";
import TagManager from "react-gtm-module";
import Layout from "../components/Layout";

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const start = useCallback(() => setLoading(true), []);
  const end = useCallback(() => setLoading(false), []);
  useEffect(() => {
    router.events.on("routeChangeStart", start);
    router.events.on("routeChangeComplete", end);
    router.events.on("routeChangeError", end);
    return () => {
      router.events.off("routeChangeStart", start);
      router.events.off("routeChangeComplete", end);
      router.events.off("routeChangeError", end);
    };
  }, [router, start, end]);

  useEffect(() => {
    const gtmTag = process.env.NEXT_PUBLIC_GTM_CONTAINER;
    TagManager.initialize({
      gtmId: gtmTag,
    });
  }, []);
  return (
    <ChakraProvider theme={chakraTheme}>
      <FaustProvider pageProps={pageProps}>
        <AnimatePresence
          mode="wait"
          initial={false}
          onExitComplete={() => window.scrollTo(0, 0)}
        >
          <Layout>
            {loading ? (
              <PageLoader />
            ) : (
              <Component {...pageProps} key={router.asPath} />
            )}
          </Layout>
        </AnimatePresence>
      </FaustProvider>
    </ChakraProvider>
  );
}
