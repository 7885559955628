import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { wpStyles } from "../wp-styles";

// define the base styles of the component
const baseStyle = defineStyle({
  borderRadius: "none", // add a border radius
  fontWeight: 500, // change the font weight
  textDecor: "none",
  cursor: "pointer",
  py: 2,
  color: "blue.500",
  _hover: {
    textDecor: "none",
  },
});

// define which sizes, variants, and color schemes are applied by default
const defaultProps = {
  variant: "outline",
};

const roundVariant = defineStyle((props) => {
  return {
    rounded: "4",
  };
});

const outlineVariant = defineStyle({
  border: "2px", // change the appearance of the border
  borderColor: wpStyles.colors.primary,
  borderRadius: 0, // remove the border radius
  color: wpStyles.colors["cyan-dark"],
  bg: "white",
  px: 8,
  _hover: {
    bg: wpStyles.colors["primary-light"],
  },
});

const solidVariant = defineStyle({
  borderRadius: 0, // remove the border radius
  color: "white",
  bg: "blackAlpha.900",
  fontWeight: "bold",
  py: 6,
  _hover: {
    bg: "blackAlpha.800",
  },
});

// define the custom variants
const variants = {
  round: roundVariant,
  outline: outlineVariant,
  solid: solidVariant,
};

// export the component theme
export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants,
  defaultProps,
});
