import { Box, Container, Heading, Stack } from "@chakra-ui/react";
import parse from "html-react-parser";
import useSite from "../../hooks/use-site";
import options from "../../support/parse-settings";
import NewsletterSidebar from "./components/NewsletterSidebar";
import NewsletterBanner from "./components/NewsletterBanner";

const SingleNewsletter = () => {
  const {
    newsletter: { content, newsletter },
  } = useSite();

  const { backgroundColor, h2Colors, h4Color, linkColors } = newsletter;

  return (
    <Box
      bg={backgroundColor}
      py={8}
      sx={{
        h2: {
          bg: h2Colors,
          color: "white",
          py: 2,
          px: 4,
          fontSize: "2xl",
          fontWeight: "bold",
        },
        h3: {
          fontSize: "lg",
          fontWeight: "bold",
        },
        h4: {
          color: h4Color,
        },
        a: {
          color: linkColors,
        },
        ol: {
          pl: 8,
        },
      }}
    >
      <Container maxW="1200px" fontSize="large">
        <NewsletterBanner />
        <Stack spacing={8} direction={{ base: "column", md: "row" }}>
          <Box
            as="main"
            maxW={{ base: "100%", md: "850px" }}
            sx={{
              ".chakra-container": {
                px: 0,
              },
            }}
          >
            {content && parse(content, options)}
          </Box>
          <Box
            flex="1"
            order={{
              base: -1,
              md: 2,
            }}
          >
            <NewsletterSidebar />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default SingleNewsletter;
