import { defineStyleConfig, defineStyle } from "@chakra-ui/react";

// define the base styles of the component
const baseStyle = defineStyle({
  fontSize: "auto",
  fontWeight: "normal",
});

// define custom sizes
const sizes = {
  sm: defineStyle({
    fontSize: "auto",
  }),
  md: defineStyle({
    fontSize: "auto",
  }),
  lg: defineStyle({
    fontSize: "auto",
  }),
  xl: defineStyle({
    fontSize: "auto",
  }),
};

export const headingTheme = defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps: { fontSize: "auto" },
});
