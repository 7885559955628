import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

// define the base styles of the component
const baseStyle = {
  borderRadius: "xl", // add a border radius
  fontWeight: "medium", // change the font weight
};

// define custom sizes
const sizes = {
  xl: defineStyle({
    maxW: "1280px",
  }),
  xxl: defineStyle({
    maxW: "1440px",
  }),
};

// define which sizes, variants, and color schemes are applied by default
const defaultProps = {
  size: "xl",
};

const roundVariant = defineStyle((props) => {
  return {
    rounded: "4",
  };
});

const noMobilePadding = defineStyle((props) => {
  return {
    px: { base: 0, md: 4 },
  };
});

// define the custom variants
const variants = {
  round: roundVariant,
  "no-mobile-p": noMobilePadding,
};

// export the component theme
export const containerTheme = defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps,
});
