import { Box, Container, Heading, Stack } from "@chakra-ui/react";
import useSite from "../../hooks/use-site";
import { wpStyles } from "../../theme/wp-styles";
import parse from "html-react-parser";
import options from "../../support/parse-settings";
import ShareLinks from "./components/ShareLinks";
import { formatDate } from "../../support/functions";
import Categories from "./components/Categories";

const SinglePost = () => {
  const {
    post: { content, title, date },
  } = useSite();

  const postDate = new Date(date);
  return (
    <Box bg={wpStyles.colors["primary-light"]} py={8}>
      <Container bg="white" p={8}>
        {title && (
          <Heading
            as="h1"
            fontSize={{
              base: wpStyles.fontSize.lg,
              md: wpStyles.fontSize.huge,
            }}
          >
            {title}
          </Heading>
        )}
        <Stack
          direction={{ base: "column", md: "row" }}
          borderTop="1px"
          borderBottom="1px"
          borderColor={wpStyles.colors.border}
          py={4}
          my={4}
          align="center"
          fontSize={wpStyles.fontSize.sm}
        >
          <Box>
            <Categories />
          </Box>
          <Box>{formatDate(postDate)}</Box>
          <ShareLinks />
        </Stack>
        {content && parse(content, options)}
      </Container>
    </Box>
  );
};

export default SinglePost;
