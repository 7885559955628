import React from "react";
import {
  Box,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Icon,
} from "@chakra-ui/react";
import DesktopSubNav from "./DesktopSubNav";
import useSite from "../../../hooks/use-site";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Link from "next/link";
import { wpStyles } from "../../../theme/wp-styles";

const DesktopNav = () => {
  const { getHeaderMenu } = useSite();

  const menu = getHeaderMenu();

  if (!menu) {
    return <></>;
  }

  return (
    <Stack
      direction={"row"}
      spacing={4}
      sx={{
        a: {
          textDecor: "none",
        },
      }}
    >
      {menu.map((navItem) => (
        <Box key={navItem.id}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Button
                p={2}
                fontSize={wpStyles.fontSize.sm}
                fontWeight={600}
                _hover={{
                  textDecoration: "none",
                  color: "blackAlpha.900",
                }}
                rightIcon={
                  navItem.children.length ? (
                    <Icon as={ChevronDownIcon} size="sm" />
                  ) : (
                    false
                  )
                }
                as="span"
                textTransform="uppercase"
                variant="link"
                sx={{
                  a: {
                    color: "gray.600",
                  },
                }}
                aria-label={`${navItem.label} Page`}
              >
                <Link href={navItem.url}>
                  <a>{navItem.label}</a>
                </Link>
              </Button>
            </PopoverTrigger>

            {navItem.children.length ? (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={"white"}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.id} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            ) : (
              <></>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};
export default DesktopNav;
