import { gql } from "@apollo/client";
import NewsletterWrapper from "../components/NewsletterWrapper";
import SingleNewsletter from "../components/SingleNewsletter";
import * as MENUS from "../constants/menus";
import {
  BlogInfoFragment,
  NavigationMenuFragment,
  SiteSettingsFragment,
} from "../fragments/GeneralSettings";
import { SingleFormFragment } from "../fragments/GravityForms";

export default function Component(props) {
  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }

  const siteProps = Object.assign({}, props.data, {
    acfData: JSON.parse(props.data.newsletter.blockData),
    postType: "post",
  });
  console.log("Site Props", siteProps);
  return (
    <NewsletterWrapper siteData={siteProps}>
      <SingleNewsletter />
    </NewsletterWrapper>
  );
}

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${BlogInfoFragment}
  ${NavigationMenuFragment}
  ${SingleFormFragment}
  ${SiteSettingsFragment}
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $asPreview: Boolean = false
    $formId: ID = "1"
  ) {
    newsletter(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      content
      blockData
      seo {
        canonical
        title
        metaDesc
        focuskw
        schema {
          pageType
          articleType
          raw
        }
      }
      newsletter {
        active
        backgroundColor
        bannerDateColor
        bannerImage {
          mediaItemUrl
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        contentHeaderBackgroundColor
        fieldGroupName
        h2Colors
        h4Color
        linkColors
        season
        sidebarMenuBackgroundColor
      }
    }
    themeStylesheet {
      value
    }
    generalSettings {
      ...BlogInfoFragment
    }
    footer1: menuItems(where: { location: FOOTER_COLUMN_1 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer2: menuItems(where: { location: FOOTER_COLUMN_2 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer3: menuItems(where: { location: FOOTER_COLUMN_3 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer4: menuItems(where: { location: FOOTER_COLUMN_4 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      last: 10000
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    themeGeneralSettings {
      ...SiteSettingsFragment
    }

    newsletterSignup: gfForm(id: $formId, idType: DATABASE_ID) {
      ...SingleFormFields
    }
  }
`;
