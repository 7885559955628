import { Box, Container, Heading, SimpleGrid, Image } from "@chakra-ui/react";
// import Image from "next/image";
import Link from "next/link";
import useSite from "../../hooks/use-site";

const ArchiveMembershipPlans = () => {
  const {
    page: { nodes },
  } = useSite();
  return (
    <Container my={8}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={4}>
        {nodes.map(({ title, uri, logo }, i) => (
          <Link href={uri} passHref key={i}>
            <Box
              as="a"
              display="block"
              p={4}
              border="1px"
              borderColor="gray.500"
              textAlign="center"
              textDecor="none"
            >
              <Box width="235px" height="35px" mx="auto" position="relative">
                <Image
                  src={logo.url.replace(
                    process.env.NEXT_PUBLIC_FRONTEND_URL,
                    process.env.NEXT_PUBLIC_WORDPRESS_URL
                  )}
                  maxH="100%"
                  mx="auto"
                  alt={logo.alt}
                  layout="fill"
                />
              </Box>
              <Heading as={"h4"} mt="4">
                {title}
              </Heading>
            </Box>
          </Link>
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default ArchiveMembershipPlans;
