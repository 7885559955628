import { Box, Container, Heading } from "@chakra-ui/react";
import useSite from "../../hooks/use-site";
import { wpStyles } from "../../theme/wp-styles";

const PageBanner = () => {
  const site = useSite();
  const title = site[site.postType]?.title;
  return (
    <Box
      py="6"
      borderTop="1px"
      borderBottom="1px"
      borderColor={wpStyles.colors.border}
      mb={12}
    >
      <Container textAlign="center">
        <Heading
          fontSize={{
            base: wpStyles.fontSize.lg,
            md: wpStyles.fontSize.xl,
            lg: wpStyles.fontSize.colossal,
          }}
          as="h1"
          mb="0"
        >
          {title}
        </Heading>
      </Container>
    </Box>
  );
};

export default PageBanner;
