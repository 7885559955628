import React, { useRef } from "react";
import {
  Stack,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  useDisclosure,
  Spacer,
  Box,
  DrawerHeader,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import MobileNavItem from "./MobileNavItem";
import useSite from "../../../hooks/use-site";
import { ReactComponent as Logo } from "../../../assets/awa-logo.svg";

const MobileNav = () => {
  const { getHeaderMenu } = useSite();

  const menu = getHeaderMenu();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  if (!menu) {
    return null;
  }
  return (
    <>
      <IconButton
        ref={btnRef}
        onClick={onOpen}
        icon={
          isOpen ? (
            <CloseIcon w={3} h={3} />
          ) : (
            <HamburgerIcon color="green.500" w={5} h={5} />
          )
        }
        variant={"ghost"}
        aria-label={"Toggle Navigation"}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p={0}>
            <Spacer borderBottom="1px" borderBottomColor="gray.300" h="1px" />
            <Stack bg={"white"} px={4} pt={12} overflow="scroll">
              {menu.map((navItem) => (
                <MobileNavItem key={navItem.id} {...navItem} />
              ))}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileNav;
