import { SiteContext, useSiteContext } from "../../hooks/use-site";
import GlobalStyles from "../GlobalStyles";
import SEO from "../SEO";

const NewsletterWrapper = ({ children, siteData }) => {
  return (
    <SiteContext.Provider value={useSiteContext(siteData)}>
      <SEO />
      <GlobalStyles />
      {children}
    </SiteContext.Provider>
  );
};

NewsletterWrapper.defaultProps = {
  children: {},
  siteData: {},
};

export default NewsletterWrapper;
