import React from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";

import states from "./states.json";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const annotationOffsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const stateLabelOffsets = {
  FL: [9, 0],
  LA: [-9, 0],
  MI: [10, 30],
};

const MapChart = ({ nosale, productColor }) => {
  return (
    <ComposableMap projection="geoAlbersUsa">
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map((geo) => {
              // Geo does not include state abbreviation
              // Using states date to filter to match the geo.id
              // Than cross check to see if its included in nosale states
              const val = states.find((obj) => obj.val === geo.id);
              let bg = productColor;
              if (val && nosale.includes(val.id)) {
                bg = "#ddd";
              }
              return (
                <Geography
                  key={geo.rsmKey}
                  stroke="#FFF"
                  geography={geo}
                  fill={bg}
                />
              );
            })}
            {geographies.map((geo) => {
              const centroid = geoCentroid(geo);
              const cur = states.find((s) => s.val === geo.id);
              let color = nosale.includes(cur.id) ? `#000` : "#fff";

              const positionY =
                cur.id in stateLabelOffsets
                  ? stateLabelOffsets[cur.id][1]
                  : "2";
              const positionX =
                cur.id in stateLabelOffsets
                  ? stateLabelOffsets[cur.id][0]
                  : "0";

              return (
                <g key={geo.rsmKey + "-name"}>
                  {cur &&
                    centroid[0] > -160 &&
                    centroid[0] < -67 &&
                    (Object.keys(annotationOffsets).indexOf(cur.id) === -1 ? (
                      <Marker coordinates={centroid}>
                        <text
                          y={positionY}
                          x={positionX}
                          fill={color}
                          fontSize={`10px`}
                          textAnchor="middle"
                        >
                          {cur.id}
                        </text>
                      </Marker>
                    ) : (
                      <Annotation
                        subject={centroid}
                        dx={annotationOffsets[cur.id][0]}
                        dy={annotationOffsets[cur.id][1]}
                      >
                        <text
                          x={4}
                          fill={
                            annotationOffsets[cur.id][2] !== "undefined"
                              ? annotationOffsets[cur.id][2]
                              : "#000"
                          }
                          fontSize={`10px`}
                          alignmentBaseline="middle"
                        >
                          {cur.id}
                        </text>
                      </Annotation>
                    ))}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  );
};

export default MapChart;
