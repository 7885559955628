import { gql } from "@apollo/client";
import ArchiveMembershipPlans from "../components/ArchiveMembershipPlans";
import PageBanner from "../components/PageBanner";
import SiteWrapper from "../components/SiteWrapper";
import * as MENUS from "../constants/menus";
import {
  BlogInfoFragment,
  SiteSettingsFragment,
  NavigationMenuFragment,
} from "../fragments/GeneralSettings";
import { SingleFormFragment } from "../fragments/GravityForms";

export default function Component(props) {
  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }
  // Change membershiplans key to page

  const pageData = { ...props.data };

  pageData.page = {
    ...props.data.membershipplans,
    featuredImage: false,
    title: "Membership Plans",
  };

  pageData.postType = "page";

  return (
    <SiteWrapper siteData={pageData}>
      <PageBanner />
      <ArchiveMembershipPlans />
    </SiteWrapper>
  );
}

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${BlogInfoFragment}
  ${NavigationMenuFragment}
  ${SingleFormFragment}
  ${SiteSettingsFragment}
  query GetPageData($headerLocation: MenuLocationEnum, $id: ID = "1") {
    membershipplans(last: 1000) {
      nodes {
        title
        uri
        logo {
          alt
          url
          width
          height
        }
      }
    }
    themeStylesheet {
      value
    }
    generalSettings {
      ...BlogInfoFragment
    }
    footer1: menuItems(where: { location: FOOTER_COLUMN_1 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer2: menuItems(where: { location: FOOTER_COLUMN_2 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer3: menuItems(where: { location: FOOTER_COLUMN_3 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer4: menuItems(where: { location: FOOTER_COLUMN_4 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      last: 10000
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    themeGeneralSettings {
      ...SiteSettingsFragment
    }

    newsletterSignup: gfForm(id: $id, idType: DATABASE_ID) {
      ...SingleFormFields
    }
  }
`;
