import {
  Flex,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import options from "../../../support/parse-settings";

const BenefitModel = ({
  title,
  logo,
  content,
  isOpen,
  onClose,
  productColor,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="1000px">
          <ModalHeader bg={"blackAlpha.800"} color="white" fontSize="lg">
            <Flex alignItems={"center"} gap={8} color="white">
              {logo && (
                <Image
                  loading="lazy"
                  bg="white"
                  src={logo.url}
                  p="2"
                  maxW={"180px"}
                  maxH={"90px"}
                  height="auto"
                  alt={title}
                />
              )}{" "}
              {title}
            </Flex>
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody p="6">{content && parse(content, options)}</ModalBody>
          <ModalFooter bg="blackAlpha.200">
            <Button
              color="white"
              bg={productColor}
              _hover={{
                bg: "black",
              }}
              mr={3}
              size="sm"
              onClick={onClose}
              variant="solid"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BenefitModel;
