import { Box, Center, Image, useDisclosure } from "@chakra-ui/react";
import BenefitModel from "./BenefitModel";
import { wpStyles } from "../../../theme/wp-styles";

const BenefitSingle = ({ title, logo, excerpt, productColor }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Center
        onClick={onOpen}
        boxShadow="md"
        cursor="pointer"
        h={"100px"}
        w="100%"
        flex="1"
        border={"1px"}
        borderColor="gray.300"
        justifyContent="center"
        transition="box-shadow 0.3s ease"
        bg="white"
        _hover={{
          boxShadow: "sm",
        }}
      >
        <Box>
          {logo && (
            <Image
              loading="lazy"
              src={logo.url}
              alt={title}
              maxH="60px"
              maxW="80%"
              mx="auto"
            />
          )}
          <Box
            fontSize={wpStyles.fontSize.sm}
            fontWeight="bold"
            color="content.500"
            textAlign="center"
            mt={logo ? 2 : 0}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Box>
      </Center>
      <BenefitModel
        title={title}
        logo={logo}
        content={excerpt}
        isOpen={isOpen}
        onClose={onClose}
        productColor={productColor}
      />
    </>
  );
};

export default BenefitSingle;
