import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import AccordionTitle from "./AccordionTitle";
import MapChart from "./MapChart";
import domToReact from "html-react-parser";
import options from "../../../support/parse-settings";
import useSite from "../../../hooks/use-site";
import { wpStyles } from "../../../theme/wp-styles";

const Eligibility = () => {
  const {
    planData: { eligibility, productColor, title },
  } = useSite();
  const { sectionTitle, text, nosale } = eligibility;
  return (
    <AccordionItem>
      <AccordionButton aria-label={sectionTitle}>
        <AccordionTitle title={sectionTitle} />
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {text && (
          <Box px="8" fontSize={wpStyles.fontSize.sm}>
            {domToReact(text, options)}
          </Box>
        )}
        {nosale && (
          <Box
            maxW="800px"
            mx="auto"
            mt="8"
            position="relative"
            fontSize={wpStyles.fontSize.sm}
          >
            <MapChart nosale={nosale} productColor={productColor} />
            <Flex justifyContent={"center"} gap="4">
              <Box
                pl="35px"
                position="relative"
                _before={{
                  content: '""',
                  w: "25px",
                  h: "25px",
                  bg: productColor,
                  position: "absolute",
                  left: "0",
                  top: "0",
                }}
                mb="4"
              >
                Available
              </Box>
              <Box
                pl="35px"
                position="relative"
                _before={{
                  content: '""',
                  w: "25px",
                  h: "25px",
                  bg: "#ddd",
                  position: "absolute",
                  left: "0",
                  top: "0",
                }}
              >
                Not Available
              </Box>
            </Flex>
            <Text
              textAlign="center"
              fontSize="sm"
            >{`${title} is not available in ${nosale.map(
              (state) => `${state}`
            )}`}</Text>
          </Box>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Eligibility;
