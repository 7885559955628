import { Box, Container, Heading, SimpleGrid } from "@chakra-ui/react";
// import Image from "next/image";
import Link from "next/link";
import useSite from "../../hooks/use-site";

const ArchiveNewsletter = () => {
  const {
    page: { nodes },
  } = useSite();
  console.log("nodes", nodes);
  return (
    <Container my={8}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={4}>
        {nodes && nodes.length > 0 ? (
          nodes.map(({ title, uri }, i) => (
            <Link href={uri} passHref key={i}>
              <Box
                as="a"
                display="block"
                p={4}
                border="1px"
                borderColor="gray.500"
                textAlign="center"
                textDecor="none"
              >
                <Heading as={"h4"} mt="4">
                  {title}
                </Heading>
              </Box>
            </Link>
          ))
        ) : (
          <Heading as={"h4"}>No newsletters found</Heading>
        )}
      </SimpleGrid>
    </Container>
  );
};

export default ArchiveNewsletter;
