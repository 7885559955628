import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Box,
  Image,
  Heading,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import AccordionTitle from "./AccordionTitle";
import parse from "html-react-parser";
import options from "../../../support/parse-settings";
import BenefitModel from "./BenefitModel";
import useSite from "../../../hooks/use-site";
import { wpStyles } from "../../../theme/wp-styles";

const HealthCarePrograms = () => {
  const {
    planData: { healthCarePrograms },
  } = useSite();
  const { sectionTitle, benefits } = healthCarePrograms;

  if (!benefits.length) {
    return null;
  }

  return (
    <AccordionItem>
      <AccordionButton aria-label={sectionTitle}>
        <AccordionTitle title={sectionTitle} />
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {benefits &&
          benefits.map(({ title, logo, excerpt, short, content }) => (
            <Flex
              key={title}
              mb={8}
              gap={8}
              borderBottom="1px"
              borderBottomColor="gray.200"
              py={8}
              wrap={["wrap", "wrap", "nowrap"]}
              _last={{ mb: 0, borderBottom: "none" }}
              fontSize={wpStyles.fontSize.sm}
            >
              <Box w={["100%", "100%", "20%"]}>
                {logo && (
                  <Image
                    loading="lazy"
                    src={logo.url}
                    maxW={["200px", "200px", "100%"]}
                    alt={title}
                  />
                )}
              </Box>
              <Box w={["100%", "100%", "80%"]}>
                <Heading
                  as="h4"
                  fontFamily="body"
                  fontSize={wpStyles.fontSize.sm}
                >
                  {short ? short : title}
                </Heading>
                <Box>{parse(excerpt, options)}</Box>
                {content && (
                  <ContentOverlay title={title} content={content} logo={logo} />
                )}
              </Box>
            </Flex>
          ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

const ContentOverlay = ({ title, content, logo }) => {
  const {
    planData: { productColor },
  } = useSite();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        bg={productColor}
        color="white"
        mt={8}
        _hover={{
          bg: "black",
        }}
        onClick={onOpen}
        variant="solid"
      >
        Learn More
      </Button>
      <BenefitModel
        title={title}
        logo={logo}
        content={content}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default HealthCarePrograms;
